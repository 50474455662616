.faqs {
	width: 100%;
	max-width: 768px;
	margin: 0 auto;
	padding: 15px;
}

.faqs .faq {
	padding: 15px;
	background: #410009;
	border-radius: 10px;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
	margin-top: 1rem;
	margin-bottom: 1rem;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.faq-answer {
	font-weight: 600;
	color: #fee03a;
}
/********************************************************** //
//
//     my iphone
//
// *********************************************************/
@media (max-width: 430px) {
	.faqs .faq {
		width: 85%;
	}

	.faqs .faq .faq-question {
		font-size: small;
	}
}
/********************************************************** //
//
//     Connies iPad
//
// *********************************************************/
@media (min-width: 768px) {
	.faqs .faq {
		width: 50%;
	}
}
/********************************************************** //
//
//     my desktop
//
// *********************************************************/
@media (min-width: 1200px) {
	.faqs .faq {
		width: 40%;
	}

	.faqs .faq .faq-question {
		font-size: medium;
	}
}

.faqs .faq .faq-question {
	position: relative;
	/* font-size: 20px; */
	padding-right: 80px;
	transition: all 0.4s ease;
	color: white;
	font-weight: 600;
}

.faqs .faq .faq-question::after {
	content: '+';
	position: absolute;
	top: 50%;
	right: 0px;
	transform: translateY(-50%);
	width: 30px;
	height: 30px;
	transition: all 0.2s ease;
}

.faqs .faq .faq-answer {
	opacity: 0;
	max-height: 0;
	overflow: hidden;
	transition: all 0.2s ease;
}

.faqs .faq.open .faq-question {
	margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
	content: '-';
}

.faqs .faq.open .faq-answer {
	max-height: 1000px;
	opacity: 1;
}
