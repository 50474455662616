.home-navigation__menu-btn {
	width: 3.75rem;
	height: 3rem;
	background: transparent;
	border: none;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	/* margin-right: 2rem; */
	cursor: pointer;
	margin: 0.25rem;
}

.home-navigation__menu-btn:hover {
	background-color: #410009;
}

.home-navigation__menu-btn span {
	display: block;
	width: 3rem;
	height: 2.5px;
	background: white;
}

.home-navigation__title {
	color: #fee03a;
}

.home-navigation__title a {
	text-decoration: none;
	color: #fee03a;
}

.home-navigation__title a:hover {
	color: white;
	background-color: black;
}

.home-navigation__header-nav {
	display: none;
}

.home-navigation__drawer-nav {
	height: 100%;
}
/************************************* //
//
//  my iphone
//
//*************************************/
@media (max-width: 430px) {
	.home-navigation__title {
		font-size: x-large !important;
		margin-bottom: 0 !important;
		padding-left: 10%;
	}
}

/************************************* //
//
//  connies ipad 
//
//*************************************/
@media (min-width: 768px) {
	.home-navigation__menu-btn {
		display: none;
	}

	.home-navigation__header-nav {
		display: block;
	}
	.home-navigation__title {
		font-size: medium;
	}
}
/************************************* //
//
//  my desktop
//
//*************************************/
@media (min-width: 1600px) {
	.home-navigation__title {
		font-size: xx-large;
	}
}
