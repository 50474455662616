.button {
	font: inherit;
	padding: 0.5rem 1.5rem;
	border: 1px solid #ff0011;
	/* border: 1px solid #92562e; */
	border-radius: 4px;
	background: #ff0011;
	/* background: #92562e; */
	color: white;
	cursor: pointer;
	margin-right: 1rem;
	margin-top: 1rem;
	text-decoration: none;
	display: inline-block;
}

.button:focus {
	outline: none;
}

.button:hover,
.button:active {
	/* background: #fd893c;
	border-color: #fd893c; */
	background: #976848;
	border-color: #976848;
	color: black;
}

.button--inverse {
	background: transparent;
	color: #ff0011;
}

.button--inverse:hover,
.button--inverse:active {
	color: white;
	background: #ff0011;
}

.button--danger {
	background: #830000;
	border-color: #830000;
}

.button--danger:hover,
.button--danger:active {
	background: #f34343;
	border-color: #f34343;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
	background: #ccc;
	color: #979797;
	border-color: #ccc;
	cursor: not-allowed;
}

.button--small {
	font-size: 0.8rem;
}

.button--big {
	font-size: 1.5rem;
}
