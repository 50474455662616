.dropdownElement {
	width: 80%;
}
/**************************************************
//
//  iPhone
//
***************************************************/
@media (max-width: 430px) {
	.dropdownElement {
		width: auto;
		color: black;
		border-radius: 2%;
		border-color: black;
		border-width: 1px;
	}
	.adminRosterWidth {
		width: 100% !important;
	}
	#adminRoster {
		width: 80%;
		height: auto;
		margin-left: auto;
	}
	.AdminRoster th {
		font-size: x-small;
	}
	.AdminRoster2 th {
		font-size: x-small;
	}
	#adminPlayoffRoster {
		width: 80%;
		height: 100px;
		margin: auto;
	}
	.gameStatus {
		font-family: Montserrat, Work Sans, sans-serif;
		font-weight: bold;
		text-align: center;
		font-size: small;
	}
	.gameSummary {
		font-family: Montserrat, Work Sans, sans-serif;
		font-weight: bold;
		text-align: center;
		font-size: small;
		/* display: flex;
	  align-items: center; */
		margin-left: auto;
		margin-right: auto;
		display: block;
	}
	.playoffTeamName {
		font-weight: bold;
		font-size: small;
	}
}
/*************************************************** /
/
/    iPhone landscape
/
/ ***************************************************/
@media (min-width: 600px) {
	.adminRosterWidth {
		width: 70%;
	}
	#adminRoster {
		width: 50%;
		height: auto;
		margin-left: auto;
	}
	#adminPlayoffRoster {
		width: 80%;
		height: 100px;
		margin: auto;
	}
	.gameStatus {
		font-family: Montserrat, Work Sans, sans-serif;
		font-weight: bold;
		text-align: center;
		font-size: small;
	}
	.AdminRoster {
		font-size: small;
	}
	.dropdownElement {
		width: auto;
		color: black;
		border-radius: 2%;
		border-color: black;
		border-width: 1px;
	}
	.gameSummary {
		font-family: Montserrat, Work Sans, sans-serif;
		font-weight: bold;
		text-align: center;
		font-size: small;
		/* display: flex;
	  align-items: center; */
		margin-left: auto;
		margin-right: auto;
		display: block;
	}
}

/************************************
//
//  my desktop
//
*************************************/
@media (min-width: 1600px) {
	.adminRosterWidth {
		width: 40%;
	}
	#adminRoster {
		width: 40%;
		height: auto;
		margin-left: auto;
	}
	#adminPlayoffRoster {
		width: 40%;
		height: 100px;
		margin: auto;
	}
	.gameStatus {
		font-family: Montserrat, Work Sans, sans-serif;
		font-weight: bold;
		text-align: center;
		font-size: larger;
	}

	.dropdownElement {
		width: auto;
		color: black;
		border-radius: 2%;
		border-color: black;
		border-width: 1px;
	}

	.gameSummary {
		font-family: Montserrat, Work Sans, sans-serif;
		font-weight: bold;
		text-align: center;
		font-size: larger;
		/* display: flex;
	  align-items: center; */
		margin-left: auto;
		margin-right: auto;
		display: block;
	}
	.playoffTeamName {
		font-weight: bold;
		font-size: xx-large;
	}
}

table {
	border-collapse: collapse;
	border-radius: 6px;
}

table thead {
	padding: 1px !important;
}

.AdminRoster th {
	border-bottom: 1px solid black;
	color: white;
	padding: 5px;
	background-color: #92562e;
	text-align: center;
}

/*This right here controls the width of the schedule screen */
.AdminRoster {
	background-color: #e0e0e0;
	overflow-x: auto;
	width: auto;
	margin: auto;
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.AdminRoster tr {
	height: 10px;
}

.AdminRoster td {
	text-align: center;
	padding: 10px;
	height: 10px;
}
/**/
/**/
/**/
/**/
.AdminRoster2 {
	background-color: #e0e0e0;
	overflow-x: auto;
	width: auto;
	margin: auto;
	margin-top: 2rem;
}

.AdminRoster2 th {
	border-bottom: 1px solid black;
	color: white;
	padding: 1px;
	background-color: #92562e;
	text-align: center;
}

.AdminRoster2 tr {
	padding: 1px;
	background-color: #92562e;
	text-align: center;
}

.AdminRoster2 td {
	padding: 10px;
	text-align: center;
}

h2 {
	text-align: center;
}

/* This right here seemed to be the thing that got the table
to look good on mobile. */

.homeRosterHeader h1,
h3 {
	text-align: left;
}

.visitorRosterHeader h1,
h3 {
	text-align: left;
}

.h3Left {
	text-align: left;
}
/* //
//
//
//
//
//
//
//
//
// */
/* #adminRoster {
	height: auto;
	margin-left: auto;
} */

#adminRoster tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}

#adminRoster tr:nth-child(odd) {
	background-color: white;
}

/* #adminPlayoffRoster {
	width: 50%;
	height: 100px;
	margin: auto;
} */

#adminPlayoffRoster tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}

#adminPlayoffRoster tr:nth-child(odd) {
	background-color: white;
}

table thead {
	padding: 10px;
}

button:hover {
	background-color: white;
}

.navlinks {
	-webkit-appearance: button;
	text-decoration: none;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	background-color: #e0e0e0;
	border-radius: 0;
	margin: 0rem !important;
	padding: 1px 6px;
	border-width: 2px;
	border-style: outset;
	border-color: black;
	color: black;
}

.navlinks:hover {
	background-color: white;
	color: black;
}

.statusDropdown {
	font-family: Montserrat, Work Sans, sans-serif;
	background: white;
	width: 50%;
}

/* textarea */

.summaryTextArea {
	background-color: white;
}
