.player-form {
	list-style: none;
	margin: 0 auto;
	padding: 1rem;
	width: 90%;
	max-width: 40rem;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
	border-radius: 6px;
	background: white;
}

/* I commented out the height 1000px on 12/31/2023.  See if that screws things up... */
.team-form {
	list-style: none;
	margin-top: 0px;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 6rem;
	padding: 1rem;
	width: auto;
	max-width: 50rem;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
	border-radius: 6px;
	background: white;
	height: auto;
	/* height: 1000px; */
	overflow-y: auto;
}

.numberInput {
	width: 80px;
}

table {
	width: 80%;
	border-collapse: collapse;
	margin: auto;
	overflow-y: auto;
}

.form-table {
	width: 100%;
	height: 100px;
}

.AddPlayers th {
	border-bottom: 1px solid black;
	color: white;
	padding: 10px;
	background-color: #92562e;
	text-align: center;
}
/**************************************************
//
//  iPhone
//
***************************************************/
@media (max-width: 430px) {
	.AddPlayers th {
		font-size: x-small;
	}
	.AddPlayers {
		background-color: #e0e0e0;
		width: 100%;
		margin-top: 4rem;
	}
}
/**************************************************
//
//  my desktop
//
***************************************************/
@media (min-width: 1600px) {
	.AddPlayers {
		background-color: #e0e0e0;
		overflow-x: auto;
		width: 60%;
		margin-top: 4rem;
	}
}

h3 {
	text-align: center;
}

.addPhotoBtn {
	display: table;
	margin-right: auto !important;
	margin-left: auto !important;
}
