#roster tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}

#roster tr:nth-child(odd) {
	background-color: white;
}

#teamSchedule tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}

#teamSchedule tr:nth-child(odd) {
	background-color: white;
}
table {
	width: 50%;
	height: 200px;
	border-collapse: collapse;
	margin: auto;
	overflow-x: auto;
}

.Roster {
	background-color: #e0e0e0;
	margin-bottom: 3rem;
	/* background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7)),
		url(../../shared/components/images/pennHills_logo2.jpg) no-repeat center
			center / cover; */
}

.Roster th {
	border-bottom: 1px solid black;
	color: #410009;
	background-color: #fee03a;
	text-align: center;
	font-size: large;
}

td {
	text-align: center;
	border: 2px solid black;
	/* font-size: medium; */
}

h1 {
	text-align: center;
}

h3 {
	text-align: center;
	font-family: Montserrat, Work Sans, sans-serif;
}

#teamSchedule {
	width: 60%;
}

#roster {
	/* width: 50%; */
	height: auto;
}

.selectDivisionDropdown {
	background-color: white !important;
	border-color: black;
	border-radius: 2%;
	color: black;
}

.rosterContainer {
	border: #fee03a;
	border-style: groove;
	border-width: thick;
	border-radius: 10px;
	margin-bottom: 6rem;
	padding: 5rem;
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: max-content;
	background: #410009;
	color: white;
}
.disclaimer {
	border: #fee03a;
	border-style: groove;
	border-width: thick;
	border-radius: 10px;
	margin-bottom: 6rem;
	padding-bottom: 5rem;
	padding-left: 5rem;
	padding-right: 5rem;
	padding-top: 2rem;
	display: block;
	margin-left: auto;
	margin-right: auto;
	background: #410009;
	color: white;
	width: 50%;
}

/************************************
//
//  iPhone
//
*************************************/
@media (max-width: 430px) {
	#roster {
		width: 90%;
	}
	.playerPhoto {
		width: 35%;
	}
	img {
		width: 35%;
	}
	#teamSchedule {
		width: auto;
	}
	td {
		font-size: small;
	}
	.Roster th {
		font-size: xx-small;
	}
	.selectDivisionDropdown {
		width: 50%;
		font-size: small;
	}
	.rosterContainer {
		padding: 1rem;
		width: 90%;
	}
	.disclaimer {
		padding: 1rem;
		width: 90%;
	}
	.disclaimer p {
		font-size: small;
		margin-bottom: 1rem !important;
	}
	.disclaimer li {
		margin-left: 3%;
	}
}
/************************************* //
//
//  iPhone landscape (I think)
//
//*************************************/
@media (min-width: 600px) {
	#roster {
		width: 90%;
	}
	.playerPhoto {
		width: 25%;
	}
	img {
		width: 25%;
	}
	#teamSchedule {
		width: auto;
	}
	td {
		font-size: small;
	}
	.selectDivisionDropdown {
		width: 60% !important;
	}
}
/************************************* //
//
//  connies ipad 
//
//*************************************/
@media (min-width: 768px) {
	#roster {
		width: 90%;
	}
	.playerPhoto {
		width: 25%;
	}
	img {
		width: 25%;
	}
	td {
		font-size: small;
	}
	.selectDivisionDropdown {
		width: 30% !important;
	}
	.disclaimer {
		padding: 1rem;
		width: 70%;
	}
}
/************************************
//
//  connies laptop
//
*************************************/
@media (min-width: 1200px) {
	#roster {
		width: 60%;
	}
	.playerPhoto {
		width: 15%;
	}
	img {
		width: 15%;
	}
	td {
		font-size: medium;
	}
	.selectDivisionDropdown {
		width: 20% !important;
	}
	.disclaimer {
		padding: 1rem;
		width: 50%;
	}
}
/************************************
//
//  my desktop
//
*************************************/
@media (min-width: 1600px) {
	#roster {
		width: 60%;
	}
	#teamSchedule {
		width: 90%;
	}
	.playerPhoto {
		width: 15%;
	}
	img {
		width: 15%;
	}
	td {
		font-size: medium;
	}
	.selectDivisionDropdown {
		width: 15% !important;
	}
	.disclaimer {
		padding: 1rem;
		width: 50%;
	}
}
/* //
//
//
//
//
//
// */
thead {
	color: white;
}
.photoDiv {
	margin-top: 0.5rem;
	margin-bottom: 2rem;
}
.playerPhoto {
	display: block;
	margin: auto;
	border-radius: 2%;
	border: solid;
	border-width: 4px;
	border-color: black;
	/* width: 9%; */
}
