.infoLink {
	display: block;
	text-align: center;
	margin-right: auto;
	margin-left: auto;
	font-size: xx-large;
	font-weight: 700;
	text-decoration: none;
}

.infoLinkSmall {
	display: block;
	text-align: center;
	margin-right: auto;
	margin-left: auto;
	font-size: large;
	font-weight: 700;
	text-decoration: none;
}

.infoLink:hover {
	text-decoration: none;
}
