.card {
	position: fixed;
	/* margin-top: 4rem; */
	margin-bottom: 1rem;
	/* width: 100%; */
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
	border-radius: 3px;
	overflow: hidden;
}

.container-fluid {
	background-color: #e0e0e0;
	height: 10rem;
}

.selector {
	background: #e0e0e0;
	border-width: 3px;
	width: 30vh;
	font-size: medium;
	font-family: Montserrat, Work Sans, sans-serif;
}

.scheduleselector {
	background: white;
	border-width: 1px;
	width: 100%;
	font-size: medium;
	font-family: Montserrat, Work Sans, sans-serif;
}

.searchbyleagueorvenue {
	margin-top: 9vh;
}

@media (max-width: 430px) {
	.searchbyleagueorvenue {
		margin-top: 9vh;
		font-size: x-small;
	}
	.scheduleselector {
		font-size: x-small;
		color: black;
		width: 100%;
		align-content: center;
		border-radius: 2%;
		border-color: black;
	}
	.selector {
		background: #e0e0e0;
		border-width: 3px;
		width: 50vh;
		color: black;
		font-size: medium;
		font-family: Montserrat, Work Sans, sans-serif;
	}
}

@media (max-width: 700px) {
	.searchbyleagueorvenue {
		/* margin-top: 18vh; */
		font-size: x-small;
	}
	.scheduleselector {
		font-size: x-small;
		width: 100%;
		color: black;
		border-radius: 2%;
		border-color: black;
	}
	.selector {
		background: #e0e0e0;
		border-width: 1px;
		width: 80vh;
		color: black;
		font-size: medium;
		font-family: Montserrat, Work Sans, sans-serif;
	}
}

@media (max-width: 1100px) {
	.searchbyleagueorvenue {
		/* margin-top: 18vh; */
		font-size: x-small;
	}
	.scheduleselector {
		font-size: x-small;
		width: 100%;
		color: black;
		border-radius: 2%;
		border-color: black;
	}
	.selector {
		background: #e0e0e0;
		border-width: 1px;
		border-radius: 2%;
		border-color: black;
		/* width: 80vh; */
		color: black;
		font-size: medium;
		font-family: Montserrat, Work Sans, sans-serif;
	}
}

.dropdownForm {
	padding-left: 30%;
	padding-right: 30%;
}

/* .instructions {
	padding: 1rem;
	font-family: Montserrat, Work Sans, sans-serif;
	font-weight: bold;
	text-align: center;
	font-size: larger;
} */

/* @media (max-width: 430px) {
	.instructions {
		padding: 0rem;
	}
} */

.LTVinstructions {
	padding: 1rem;
	font-family: Montserrat, Work Sans, sans-serif;
	font-weight: bold;
	text-align: center;
	font-size: larger;
}

@media (max-width: 430px) {
	.LTVinstructions {
		font-size: medium;
		padding-top: 1rem;
		padding-bottom: 0;
	}
}

@media (max-width: 700px) {
	.LTVinstructions {
		padding-top: 3rem;
	}
}

.instructions {
	padding: 1rem;
	font-family: Montserrat, Work Sans, sans-serif;
	font-weight: bold;
	text-align: center;
	font-size: larger;
}

@media (max-width: 430px) {
	.instructions {
		font-size: medium;
		padding-top: 1rem;
		padding-bottom: 0;
	}
}

@media (max-width: 700px) {
	.instructions {
		font-size: x-small;
	}
}

/* //
//
//
//
// */

@media (max-width: 430px) {
	.selectLeagueDropdown {
		border-color: black;
		border-radius: 2%;
		border-width: 1px;
		width: 100%;
	}
}
@media (min-width: 700px) {
	.selectLeagueDropdown {
		border-color: black;
		border-radius: 2%;
		border-width: 1px;
	}
}
