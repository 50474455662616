body {
	font-family: 'Montserrat', sans-serif;
}

.newsAndUpdates {
	color: #410009;
	text-shadow: 2px 2px #fee09a;
}

.watch {
	color: #410009;
}

.faqButton {
	width: auto !important;
	margin-left: auto !important;
	margin-right: auto !important;
	display: block;
	margin-bottom: 2rem !important;
	background-color: #410009;
	color: #fee03a;
	border-radius: 5px;
	font-size: large;
	font-weight: 600;
}

.faqButton:hover {
	background-color: #740a18;
}

.tournamentSchedule {
	width: 30% !important;
}

/* .homeHeader {
	height: 70vh;
	background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5)),
		url(../images/new_logo.jpg) no-repeat center center / cover;
} */
/* .homeHeader {
	margin-top: 3rem;
	height: 55vh;
	background-image: url(../images/new_logo.jpg);
	background-color: white;
	background-position: center;
	background-size: contain;
} */
/************************************* //
//
//  iPhone 
//
//*************************************/
@media (max-width: 430px) {
	/* .homeHeader {
		height: 35vh;
		background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5)),
			url(../images/new_logo.jpg) no-repeat center center / cover;
	} */
	.homeHeader {
		height: 35vh;
		/* background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5)),
			url(../images/Sloths_Logo2.png) no-repeat center center / cover; */
		background-image: url(../images/new_logo.jpg);
		background-color: white;
		background-position: center;
		background-size: cover;
		margin-top: 3rem;
	}
	.banner {
		top: 26%;
	}

	.aboutUsText {
		margin-left: auto;
		margin-right: auto;
		width: 90% !important;
		margin-bottom: 2rem;
	}

	.faqButton {
		padding-top: 3%;
		padding-bottom: 3%;
	}

	.contactUsInputField {
		width: 80%;
		margin-left: auto;
		margin-right: auto;
		display: block;
	}
	.submitEmailBtn {
		width: 80%;
		margin-left: auto !important;
		margin-right: auto !important;
		display: block !important;
		margin-bottom: 3rem !important;
		border-color: #410009 !important;
		border: 2.5px solid !important;
		color: #410009 !important;
	}
	.tournamentSchedule {
		width: 80% !important;
	}
}
/************************************* //
//
//  iPad
//
//*************************************/
@media (min-width: 750px) {
	.homeHeader {
		margin-top: 3rem;
		height: 35vh;
		background-image: url(../images/new_logo.jpg);
		background-color: white;
		background-position: center;
		background-size: cover;
	}
	.banner {
		top: 20%;
	}
	.contactUsInputField {
		width: 80%;
		margin-left: auto;
		margin-right: auto;
		display: block;
	}
	.submitEmailBtn {
		width: 80%;
		margin-left: auto !important;
		margin-right: auto !important;
		display: block !important;
		margin-bottom: 3rem !important;
		border-color: #410009 !important;
		border: 2.5px solid !important;
		color: #410009 !important;
	}
}
/************************************* //
//
//  iPhone landscape (I think)
//
//*************************************/
@media (min-width: 600px) {
	.headingWriting {
		margin-top: 3rem;
	}
}

/************************************* //
//
//  my desktop
//
//*************************************/
@media (min-width: 1200px) {
	.homeHeader {
		margin-top: 3rem;
		height: 47vh;
		background-image: url(../images/new_logo.jpg);
		background-color: white;
		background-position: center;
		background-size: auto;
	}
	/* .homeHeader {
		height: 70vh;
		background: url(../images/new_logo.jpg) no-repeat center / cover;
	} */
	.aboutUsText {
		margin-left: auto;
		margin-right: auto;
		width: 60% !important;
		margin-bottom: 2rem;
	}
	.faqButton {
		width: auto;
		margin-left: auto !important;
		margin-right: auto !important;
		display: block;
		margin-bottom: 2rem !important;
	}
	.faqButton {
		padding-top: 0.5%;
		padding-bottom: 0.5%;
	}
	.banner {
		top: 30%;
	}
	.contactUsInputField {
		width: 80%;
		margin-left: auto;
		margin-right: auto;
		display: block;
	}
	.submitEmailBtn {
		width: 80%;
		margin-left: auto !important;
		margin-right: auto !important;
		display: block !important;
		margin-bottom: 3rem !important;
		border-color: #410009 !important;
		border: 2.5px solid !important;
		color: #410009 !important;
	}
}

.subheading {
	font-size: calc(1.475rem + 2.7vw);
	font-weight: 600;
	line-height: 1.2;
}

#player {
	width: 50% !important;
}

.nav-menu {
	background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5));
	padding: 30px;
	transition: all 0.7s;
}

.menu-item {
	font-size: 13px;
	letter-spacing: 1px;
	color: #eee;
	transition: color 0.3s;
}

.menu-item:hover {
	color: #70aed2;
}

.nav-active {
	color: #70aed2;
}

.line1,
.line2,
.line3 {
	width: 23px;
	height: 3px;
	margin: 5px;
	transition: all 0.4s;
}

.change .line1 {
	transform: rotate(-45deg) translate(-5px, 6px);
}

.change .line2 {
	opacity: 0;
}

.change .line3 {
	transform: rotate(45deg) translate(-5px, -6px);
}

.custom-navbar {
	padding: 5px 30px;
	background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7));
}

.banner {
	position: absolute;
	/* top: 30%; */
	left: 50%;
	transform: translate(-50%, -50%);
	width: 80%;
}

.banner-heading {
	animation-name: animate;
	animation-duration: 2s;
}

.banner-par {
	animation-name: animate;
	animation-duration: 2s;
	animation-delay: 0.5s;
	animation-fill-mode: backwards;
}

@keyframes animate {
	0% {
		transform: translateX(-100px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

.mission {
	background: #06043f;
	position: absolute;
}

.underline {
	width: 15% !important;
	border: 3px solid #06043f;
	margin: auto;
}

.underline-white {
	width: 20%;
	border: 3px solid white;
	margin: auto;
}

.text-sh-light-blue {
	color: #316aad;
}

.text-sh-dark-blue {
	color: #06043f;
}

.text-sh-red {
	color: #ff0011;
}

.fromLeft {
	animation-name: fromLeft;
	animation-duration: 3s;
}

.fromRight {
	animation-name: fromRight;
	animation-duration: 3s;
}

@keyframes fromLeft {
	0% {
		transform: translateX(-120px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes fromRight {
	0% {
		transform: translateX(30px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

.card-shadow {
	box-shadow: 5px 8px 20px #444;
}

.heading {
	position: absolute;
	top: 70%;
	right: 0;
	font-size: 14px;
	letter-spacing: 1px;
	background: #222;
	width: 70%;
	cursor: pointer;
	opacity: 0.7;
	transition: all 0.5s;
}

.heading:hover {
	opacity: 1;
}

.underline-dark {
	width: 150px;
	border: 3px solid #888;
	margin: auto;
}

.gallery-list-item {
	color: #777;
	cursor: pointer;
	user-select: none;
}

.active-item {
	color: #70aed2;
}
.card-list-item {
	border-bottom: 1px groove #eee;
	width: 55%;
	margin: auto;
}

.price-card-button {
	background: #f5593d;
	width: 130px;
	border-radius: 25px;
	box-shadow: 5px 8px 18px #000;
	transition: all 0.2s;
}

.price-card-button:hover {
	background: #f9793d;
	transform: translate(-2px);
}

.moveFromLeft {
	animation-name: moveFromLeft;
	animation-duration: 2s;
}

.moveFromRight {
	animation-name: moveFromRight;
	animation-duration: 2s;
}

.moveFromBottom {
	animation-name: moveFromBottom;
	animation-duration: 2s;
}

@keyframes moveFromLeft {
	0% {
		transform: translateX(-100px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes moveFromRight {
	0% {
		transform: translateX(100px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes moveFromBottom {
	0% {
		transform: translateY(100px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

/* this section is cut in two at a 105 degree angle where HALF (50%) 
of the background is very dark (.95 opacity).
The second 50% value is the black line.  If I make it something else, like
60%, you'll see the difference in how the line fades out
*/

.input {
	background: transparent;
	border-color: transparent;
	border-bottom: 2px solid #ccc;
	border-radius: 0;
	transition: all 0.8s;
}

.input:focus {
	background: transparent;
	border-color: transparent;
	box-shadow: none;
	color: #fff;
	border-bottom: 2px solid #f5593d;
}

.submit-button {
	background: #f5593d;
	color: #eee;
	transition: all 0.3s;
}

.submit-button:hover {
	background: #f9793d;
	transform: translateY(-3px);
}

.label {
	color: #777;
	display: block;
	margin-top: -70px;
	margin-left: 4px;
	font-size: 13px;
	transition: all 0.3s;
}

.input:placeholder-shown + .label {
	transform: translate(20px, 20px);
	opacity: 0;
	visibility: hidden;
}

@media (max-width: 992px) {
	.contact {
		background: linear-gradient(rgba(21, 31, 32, 1), rgba(21, 31, 32, 0.95));
	}
	.contact-form {
		width: 80%;
		margin: auto;
	}
}

.fa-cogs,
.fa-thumbs-up,
.fa-handshake {
	color: #06043f;
}

/* .fa-cogs,
.fa-thumbs-up,
.fa-handshake {
	color: white;
} */

.middle {
	height: 1000px;
}

.container-dark {
	background-color: #06043f !important;
}
.container-gray {
	background-color: #e0e0e0 !important;
}

.logo-container {
	width: 15% !important;
	height: auto !important;
}

.footer-h5 {
	text-align: center;
}

.footer-p {
	text-align: center;
}

.footer {
	position: absolute;
}

.footer-link {
	text-decoration: none;
	color: #eee;
	font-size: 13px;
	transition: all 0.3s;
}

.footer-link:hover {
	color: #708ed2;
	text-decoration: none;
}

.section {
	position: relative;
}

.logoImage1 {
}

/* Some of these are set just because I'm trying to override bootstrap settings */
.carousel-caption {
	position: relative !important;
	left: 0% !important;
	right: 0% !important;
}

.carousel-inner {
	border-radius: 4px;
}

.carousel {
	width: 60%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 5rem;
}

.carousel-header {
	color: white;
}

.carousel-text {
	color: white;
	padding-left: 10%;
	padding-right: 10%;
}

.icon {
	font-size: 5em;
}

.footer-background {
	background-color: #06043f;
}

@media (max-width: 700px) {
	.underline-white {
		width: 60%;
		border: 3px solid white;
		margin: auto;
	}

	.underline {
		width: 40% !important;
	}

	.logoRow {
		display: none;
	}
	.carousel {
		width: 100%;
	}

	.carousel-text {
		font-size: small;
	}

	.carousel-header {
		font-size: x-large;
	}

	.p-5 {
		padding: 0.5rem;
	}

	.logo-container {
		width: 35% !important;
		height: 3rem !important;
	}

	/* p {
		margin-bottom: 4rem !important;
	} */
	.caption {
		margin-bottom: 0.75rem !important;
	}

	.icon {
		font-size: 3em;
	}
}

.logoRow {
	width: 100%;
	padding-left: 5rem;
	background-color: white;
}

.videos {
	background-color: white;
}
.about {
	background-color: white;
}

.playerVideos {
	background-color: #e0e0e0;
}

/* .img-thumbnail {
	border: none !important;
	background-color: white !important;
} */

.img-thumbnail {
	max-width: none !important;
	/* width: 60% !important; */
	border: none !important;
	background-color: white !important;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.content {
	margin-right: 15%;
	margin-left: 15%;
}

/************************************* //
//
//  Carousel
//
//*************************************/
.carousel-img {
	margin: 2.5rem;
	/* width: -webkit-fill-available; */
	width: 70%;
}

.yellow {
	color: #fbe212;
}

@media (max-width: 430px) {
	.carouselPhoto {
		width: 60% !important;
		margin: auto;
		border-color: #410009;
		border-style: solid;
		border-width: thick;
		border-radius: 4px;
	}
}

@media (min-width: 700px) {
	.carouselPhoto {
		width: 60% !important;
		margin: auto;
		border-color: #000000;
		border-style: solid;
		border-width: thick;
		border-radius: 4px;
	}
}

@media (min-width: 1200px) {
	.carouselPhoto {
		width: 30% !important;
		margin: auto;
		border-color: #410009;
		border-style: solid;
		border-width: thick;
		border-radius: 4px;
	}
}
